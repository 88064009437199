<template>
    <router-link v-if="categories.product_length != 0 || categories.sub_length != 0" :to="'/m/'+boxSlug+'/'+categories.id">
        <div v-wave class="rest-category-box" :class="{ categoryBoxNoImage: categories.category_image == null }">
            <div class="rest-category-box-image">
                <img v-if="categories.category_image == null" :src="require(`@/assets/img/web/null2.png`)" alt="">
                <img v-else v-lazy="'https://meenu.co/assets/mnimg/'+categories.category_image" alt="">
            </div>
            <div class="rest-category-box-name">
                <span>{{categories.category_name}}</span>
            </div>
        </div>
    </router-link>
</template>

<script>
import { ref,reactive, computed, watch, nextTick , provide  , inject , onMounted  } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
export default {
  name: 'CategoriesBox',
  props:['categories'],
  setup(props){
    const route = useRoute();
    const store = useStore();

    // console.log(store.getters.restaurant);

    const boxSlug = route.params.slug;

    function randomColor(id) {
      const r = () => Math.floor(256 * Math.random());
      return `rgb(${r()}, ${r()}, ${r()})`;
    }
        
    return{
        boxSlug,
        randomColor
    }
    
  }
}
</script>
