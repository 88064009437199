import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';
import VueLazyload from 'vue-lazyload'
import VWave from 'v-wave'
import vClickOutside from "click-outside-vue3"


window.axios = require('axios');


global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

createApp(App)
.use(store)
.use(router)
.use(vClickOutside)
.use(VWave,{
  color: 'black',
  duration: 0.5,
  finalOpacity: 0,
  initialOpacity: 0.2})
.use(VueLazyload,{
    preLoad: 1.3,
    attempt: 1
  })
.mount('#app')

