<template>
  <RestaurantHeader :restaurantData="restaurantInfo"></RestaurantHeader>
  <!-- <restaurantSlider/> -->
  <div class="rest-category">
    <div class="wb-ct">
        <div :class="{ noImageFullContainer : categoryNoImageLength == 1}" class="rest-category-container">
            <CategoriesBox v-for="categories,index in restaurantInfo.categories" :key="index" :categories="categories"></CategoriesBox>
          </div>
      </div>
  </div>
</template>

<script>

import { ref , computed , onMounted , watch, reactive} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import RestaurantHeader from '@/components/Categories/RestaurantHeader.vue';
import CategoriesBox from '@/components/Categories/CategoriesBox.vue';
import RestaurantSlider from '@/components/Categories/RestaurantSlider.vue';

export default {
  name: 'Home',
  components: {
    RestaurantHeader,CategoriesBox,RestaurantSlider
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    if(store.state.restaurantInfo.length == 0){
      store.dispatch('get_restaurantData',route.params.slug);
    }

    const restaurantInfo = computed(() => store.getters.restaurant);
    const categoryNoImageLength = computed(() => store.getters.categoryNoImageLength);
    
    return {
      restaurantInfo,categoryNoImageLength
    }

  }
}
</script>

<style lang="scss">

.noImageFullContainer{
  grid-template-columns: 1fr;
  gap: 16px;
  .rest-category-box-image{
    display: none !important;
  }
  .rest-category-box-name{
    padding: 16px 0px !important;
  }
}

</style>
