<template>
  <div class="restaurant-slider-container">
    <swiper
      :modules="modules"
      :slides-per-view="1.1"
      :centeredSlides="true"
      :space-between="10"
      :loop="false"
      navigation
    >
      <swiper-slide @click=" router.push({ path : '/m/'+route.params.slug+'/'+sliderItem.sliderLink ,   query: { productID: sliderItem.sliderProductID }})" v-for="(sliderItem,index) in sliderItems" :key="index"><img class="restaurant-slider-image" v-lazy="require(`@/assets/img`+sliderItem.sliderImage)"></swiper-slide>
    </swiper>
    <div class="wb-ct">
    </div>
  </div>
</template>
<script>
    import {ref , reactive} from 'vue';
    import { useRouter, useRoute } from 'vue-router'
    import { Navigation, Pagination, Scrollbar, A11y , Autoplay } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/scrollbar';
    import 'swiper/css/autoplay';
  
    // Import Swiper styles
    export default {
      components: {
        Swiper,
        SwiperSlide,
      },
      setup() {
        const route = useRoute();
        const router = useRouter();
        const sliderItems = ref([
          {
            sliderImage : '/slider/slider1.jpg',
            sliderLink : '62',
            sliderProductID : "293"
          },
          {
            sliderImage : '/slider/slider2.png',
            sliderLink : '63',
            sliderProductID : "297"
          },
          {
            sliderImage : '/slider/slider3.png',
            sliderLink : '64',
            sliderProductID : "307"
          },
          {
            sliderImage : '/slider/slider4.png',
            sliderLink : '65',
            sliderProductID : "316"
          },
          {
            sliderImage : '/slider/slider5.jpg',
            sliderLink : '66',
            sliderProductID : "318"
          }
        ])
        return {
          sliderItems,
          route,
          router,
          modules: [Navigation, Pagination, Scrollbar, A11y , Autoplay ]
        };
      },
    };
  </script>

  <style lang="scss" scoped>
  .swiper{
    padding: 10px 0px 40px;
  }
  .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    height: 150px;
    width: 90vw;
    cursor: pointer;
  }
  .restaurant-slider-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media only screen and (min-width: 768px) {
    .swiper{
      height: 600px;
    }
    .swiper-slide{
      height: 100%;
    }
  }
  </style>