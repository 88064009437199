<template>
    <div v-click-outside="menuSubinfoOutside" class="menu">
        <Transition name="slide-fade">
            <div v-if="menuSubinfo" class="menu-subinfo">
                <span class="menu-subinfo-brand"><span class="bold">Meenu</span><br> Dijital Menü Sistemleri</span>
                <div class="menu-subinfo-line"></div>
                <a href="https://www.meenu.co" target="_blank" class="bold">www.meenu.co</a>
            </div>
        </Transition>
        <div class="wb-ct">
            <div class="menu-top">
                <div class="menu-left-icon button">
                    <i @click="$router.back()" class="fa-light fa-arrow-left"></i>
                </div>
                <div @click="menuSubinfo = !menuSubinfo" class="menu-meenu-logo">
                    <img src="@/assets/img/web/meenu.svg" alt="">
                </div>
                <div @click="$router.push({name: 'home'})" class="menu-menubar-logo button">
                    <i class="fa-light fa-house"></i>
                </div>
            </div>
        </div>
        <Transition name="slide-fade">
            <div v-if="openMenu" class="menu-list">
                <ul>
                    <li>
                        <router-link to="/simitchii-atakent">Simitchii Atakent</router-link>
                    </li>
                    <li>
                        <router-link to="/diyarbakir-kebap-evi-atakum">Diyarbakır Kebap Evi</router-link>
                    </li>
                    <li>
                        <router-link to="/kardesler-bursa-iskender-samsun">Kardeşler Bursa İskender</router-link>
                    </li>
                    <li>
                        <router-link to="/56-s-level-cafe-samsun">56s Level Cafe</router-link>
                    </li>
                    <li>
                        <router-link to="/mudavim-erbaa/120">Müdavim Erbaa</router-link>
                    </li>
                </ul>
            </div>
        </Transition>
    </div>
</template>

<script>

import { ref, computed, watch, nextTick , provide  , inject ,toRaw  } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'Menu',
  setup(){
    const route = useRoute();
    const router = useRouter();
    let openMenu = ref(false);
    var menuSubinfo = ref(false);    

    function outClickMenu(){
        window.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)){
            this.openMenu = false
            }
        })
    }

    const menuSubinfoOutside = (e) => {
        menuSubinfo.value = false;
    }

    const restaurantSlug = computed(() => {
        console.log(route.params.slug);
    //   return store.getters.products;
    });

    watch(router, (to,from) => {
      console.log(to,from)
    }, {})

    router.afterEach((to, from) => {
        if(to.fullPath != from.fullPath){
            openMenu.value = false;
            menuSubinfo.value = false;
        }
    });

    return{
        openMenu,outClickMenu,restaurantSlug,menuSubinfo,menuSubinfoOutside
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang="scss" scoped>

.menu-subinfo{
    & a{
        color: inherit;
    }
    background-color: #29AB1F;
    padding: 20px;
    border-radius: 20px;
    position: absolute;
    bottom: calc(100% + 24px);
    left: 0%;
    color: white;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &::after{
        content: "\f04b";
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
        font-size: 24px;
        position: absolute;
        top: calc(100% - 10px);
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        display: inline-block;
        color: #29AB1F;
    }
    .menu-subinfo-brand{
        line-height: 1.3;
    }
    span.bold{
        font-weight: 500;
    }
    a.bold{
        font-weight: 500;
    }
    .menu-subinfo-line{
        width: 100%;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
        }
    }
}
.slide-fade-enter-active,.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(40px);
  opacity: 0;
}
.menu-meenu-logo{
    cursor: pointer;
}
</style>