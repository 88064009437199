import { createStore } from 'vuex'

export default createStore({
  state: {
    restaurantInfo : '',
    restaurantProducts : '',
    productDetailModal : false
  },
  getters: {
    restaurant(state){
      return state.restaurantInfo;
    },
    products(state){
      return state.restaurantProducts;
    },
    sliderItemInfo : (state) => (id) => {
      return state.restaurantProducts.products?.find(item => item.id == id);
    },
    categoryNoImageLength(state){
      var sayac = 0;
      var length = state.restaurantInfo.categories?.forEach(element => {
        if(element.category_image != null){
          sayac = 1;
        }
      });
      if(sayac == 0){
        return 1;
      }
      else{
        return 0;
      }
    }
  },
  mutations: {
    SET_restaurantData(state,value){
      state.restaurantInfo = value;
    },
    SET_productData(state,value){
      state.restaurantProducts = value;
    },
    SET_loader(state,value){
      state.loader = value;
    },
    SET_productDetailModal(state,value){
      state.productDetailModal = value
    },
    SET_productDataDelete(state,value){
      state.restaurantProducts = null
    }
  },
  actions: {
    get_restaurantData(context,routeSlug){
      axios
        .get('https://meenu.co/api/v1/companies/'+routeSlug)
        .then(response => {
          context.commit('SET_restaurantData',response.data);
        });
    },
    get_productData(context,categoryID){ 
      axios
        .get('https://meenu.co/api/v1/categories/'+categoryID)
        .then(response => {
          context.commit('SET_productData',response.data);
        });
    }
  },
  modules: {
  }
})
