<template>
  <Transition>
    <router-view/>
  </Transition>
  <Menu></Menu>
</template>

<script>
import Menu from "@/components/Menu.vue";
export default {
  components: { Menu }
};
</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


@import url(./assets/css/style.css);
@import url(./assets/css/fontawesome.css);

</style>
