import { createRouter, createWebHistory , createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
const routes = [
  {
    path: '/m/',
    name: 'empty',
    redirect: function(){
      window.location.href = "https://www.meenu.co";
    },
  },
  {
    path: '/m/:slug',
    name: 'home',
    component: Home,
    meta: { title: 'Anasayfa' }
  },
  {
    path: '/m/:slug/:mlug',
    name: 'categoryProducts',
    component: function () {
      return import('../views/CategoryProducts.vue')
    },
    meta: { title: 'Kategoriler' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if(to.query != from.query){        
        return { top: 0 }
      }
    }
  }
})

export default router
